import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import UrlsEnum from "../../../common/requests/UrlsEnum";
import { RoundButtonHelp } from "./RoundButton";

function DocButton({ link, navbar = false }) {
	const { t, i18n } = useTranslation();

	const handleClick = () => {
		let targetURL = `${UrlsEnum.DOC}/${i18n.language?.split("-")[0]}/`;

		if (link) targetURL += link;

		window.open(targetURL, "_blank");
	};

	return (
		<>
			{navbar ? (
				<div
					className="h-[4rem] rounded-lg shadow-md text-darkgray bg-white border-[1px] border-slate-300 cursor-pointer flex items-center px-6 hover:bg-gray"
					onClick={handleClick}
				>
					<FontAwesomeIcon className="text-xl" icon={faBook} />
					<span className="font-semibold font-sm ml-3">
						{t("documentation")}
					</span>
				</div>
			) : (
				<RoundButtonHelp onClick={handleClick} />
			)}
		</>
	);
}

export default DocButton;
