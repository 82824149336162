import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DocButton from "../../../common/layouts/Global/DocButton";
import LoadingSpinner from "../../../common/layouts/Global/LoadingSpinner";
import { RoundButtonMagnifyingGlass } from "../../../common/layouts/Global/RoundButton";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../common/util/ToastUtil";
import { FetchGroupDashboard } from "../../../portail/requests/Group";
import {
	AcceptOrder,
	DeleteOrder,
	FetchGroupOrders,
} from "../../requests/Orders";
import DetailsOrderPopup from "../PopUp/DetailsOrderPopup";
import StoreOrderFilters from "../filters/StoreOrderFilters";

function StoreOrderTable({ groupId }) {
	const { t } = useTranslation();

	document.title = `KUPA Station | ${t("store")}`;

	const [filters, setFilters] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const { data: group } = useQuery([`group-${groupId}`], {
		queryFn: () => FetchGroupDashboard(groupId),
		enabled: !!groupId,
	});

	const isCustom = group?.data?.type === "CUSTOM";
	const { data, isLoading, isFetching, refetch } = useQuery(
		[`storeOrders-${groupId}`],
		{
			queryFn: () => FetchGroupOrders(groupId, filters, currentPage, 10),
			enabled: !isCustom,
		}
	);
	const { data: orders, pagination } = data?.data || {};

	const [orderState, setOrderState] = useState(null);
	const [detailsOrderPopupOpen, setDetailsOrderPopupOpen] = useState(false);

	const deleteOrder = async (order, deleteComment) => {
		ToastLoading("deleteStoreOrder");
		const data = await DeleteOrder(order.id, deleteComment);
		ToastAfterLoading(
			"deleteStoreOrder",
			data?.success,
			data?.data?.message
		);
		refetch();
		return data;
	};
	const validTypes = ["MASTER", "DISTRIBUTOR"];
	const isMaster = validTypes.includes(group?.data?.type);
	const acceptOrder = async (order, status) => {
		ToastLoading("acceptStoreOrder");
		const data = await AcceptOrder(order, status);
		ToastAfterLoading(
			"acceptStoreOrder",
			data?.success,
			data?.data?.message
		);
		refetch();
	};
	useEffect(() => {
		refetch();
	}, [filters, currentPage, refetch]);

	const handleNewFilters = async (newFilters) => {
		setCurrentPage(1);
		setFilters(newFilters);
	};

	if (isCustom || !data?.success) {
		return null;
	}

	const columns = [
		...(isMaster ? [t("client")] : []),
		t("ref"),
		t("status"),
		t("user"),
		t("total"),
		t("comment"),
		t("date"),
		t("action"),
	];
	const ActionButtons = (order) => {
		return (
			<div className="flex">
				<RoundButtonMagnifyingGlass
					functionCall={() => openDetailsPopup(order)}
				/>
			</div>
		);
	};
	const rows = orders?.map((order) => {
		const commonColumns = [
			order.ref_id,
			<span>{t(order.status)}</span>,
			order?.user_email,
			order?.items?.reduce((acc, value) => acc + value.quantity, 0),
			order.user_comment || order.user_comment !== ""
				? order.user_comment
				: "-",
			moment(order.created_at).format("YYYY-MM-DD HH:mm:ss"),
			ActionButtons(order),
		];
		return isMaster
			? [order?.client_group?.name, ...commonColumns]
			: commonColumns;
	});
	const openDetailsPopup = (order) => {
		setOrderState(order);
		setDetailsOrderPopupOpen(true);
	};
	const closeDetailsPopup = () => {
		setDetailsOrderPopupOpen(false);
	};

	if (isLoading) return <LoadingSpinner />;
	return (
		<WhiteBlockDiv
			text={t("orders")}
			text2={<DocButton link="management/store/orders" />}
		>
			<div className="w-full flex flex-col">
				<div className="pb-5 flex items-start">
					<StoreOrderFilters
						handleNewFilters={handleNewFilters}
						groupId={1}
					/>
				</div>
				<Table
					columns={columns}
					rows={rows}
					pagination={pagination}
					isLoading={isFetching}
					currentPage={currentPage}
					handlePageChange={setCurrentPage}
				/>
				<DetailsOrderPopup
					open={detailsOrderPopupOpen}
					closeMethod={closeDetailsPopup}
					acceptOrder={acceptOrder}
					cancelOrder={deleteOrder}
					orderId={orderState?.id}
					isMaster={isMaster}
				/>
				{/* <ModifyOrderPopup
					open={detailsOrderPopupOpen}
					closeMethod={closeDetailsPopup}
					acceptOrder={acceptOrder}
					cancelOrder={deleteOrder}
					order={orderState}
					isMaster={isMaster}
				/> */}
				{/* <DeletePopup
					open={deleteOrderPopupOpen}
					closeMethod={closeDeletePopup}
					deleteMethod={() => {
						deleteOrder(orderState);
					}}
					typeOfDelete={"sotre.order"}
				></DeletePopup> */}
			</div>
		</WhiteBlockDiv>
	);
}

export default StoreOrderTable;
