import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Permissions from "../../../../../common/config/permissions";
import { useAuth } from "../../../../../common/context/AuthContext";
import Restricted from "../../../../../common/context/hooks/Restricted";
import DocButton from "../../../../../common/layouts/Global/DocButton";
import LoadingSpinner from "../../../../../common/layouts/Global/LoadingSpinner";
import {
	RoundButtonPen,
	RoundButtonPlus,
	RoundButtonXMark,
} from "../../../../../common/layouts/Global/RoundButton";
import WhiteBlockDiv from "../../../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../../../common/layouts/tables/Table";
import { FetchPermissions } from "../../../../../common/requests/Permission";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../../../common/util/ToastUtil";
import { FetchGroupMembers } from "../../../../requests/Group";
import {
	AddMember,
	DeleteMember,
	UpdateMember,
} from "../../../../requests/dashboard/Members";
import CreateMemberPopup from "../../Popup/CreateMemberPopup";
import DeletePopup from "../../Popup/DeletePopup";
import ModifyMemberPopup from "../../Popup/ModifyMemberPopup";

function MembersSection({ groupId, openAccordion, id }) {
	const { t } = useTranslation();
	const { data, refetch } = useQuery([`group-${groupId}-members`], {
		queryFn: () => FetchGroupMembers(groupId),
	});
	const { data: avPermission } = useQuery(
		[`available-permissions-${groupId}`],
		{
			queryFn: () => FetchPermissions(groupId),
		}
	);
	const [deletePopupOpen, setDeletePopupOpen] = useState(false);
	const [createPopupOpen, setCreatePopupOpen] = useState(false);
	const [modifyPopupOpen, setModifyPopupOpen] = useState(false);
	const [memberModify, setMemberModify] = useState(null);

	const openDeletePopup = (member) => {
		setMemberModify(member);
		setDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setDeletePopupOpen(false);
	};

	const openCreatePopup = () => {
		setCreatePopupOpen(true);
	};

	const closeCreatePopup = () => {
		setCreatePopupOpen(false);
	};

	const openModifyPopup = (member) => {
		setMemberModify(member);
		setModifyPopupOpen(true);
	};

	const closeModifyPopup = () => {
		setModifyPopupOpen(false);
	};

	const modifyMember = async (newRole) => {
		closeModifyPopup();
		ToastLoading("modifyMember");
		const data = await UpdateMember(
			groupId,
			memberModify?.user?.id,
			newRole
		);
		ToastAfterLoading("modifyMember", data?.success, data?.data?.message);

		refetch();
	};

	const deleteMember = async () => {
		closeDeletePopup();
		ToastLoading("deleteMember");
		const data = await DeleteMember(groupId, memberModify.user.id);
		ToastAfterLoading("deleteMember", data?.success, data?.data?.message);
		refetch();
	};

	const createMember = async (memberEmail, memberRoleId) => {
		ToastLoading("createMember");
		closeCreatePopup();
		const data = await AddMember(groupId, memberEmail, memberRoleId);
		ToastAfterLoading(
			"createMember",
			data?.success,
			t(data?.data?.message)
		);

		refetch();
	};
	const { inGroup, staff } = useAuth();

	const columns = [t("name"), t("email"), t("role"), t("actions")];

	if (!data) return <LoadingSpinner />;

	const ActionButtons = ({ member }) => (
		<Restricted to={Permissions.GROUP_MEMBER_EDIT} groupId={groupId}>
			<div className="flex">
				<RoundButtonPen functionCall={() => openModifyPopup(member)} />
				<RoundButtonXMark
					functionCall={() => openDeletePopup(member)}
				/>
			</div>
		</Restricted>
	);
	const rows = data?.data?.map((member) => {
		const rankDiff = member?.role?.rank >= avPermission?.data?.rank;
		return [
			member?.user?.name,
			member?.user?.email,
			member?.role?.name,
			staff() || !inGroup(groupId) || rankDiff ? (
				<ActionButtons member={member} />
			) : null,
		];
	});

	return (
		<>
			<WhiteBlockDiv
				text={
					<>
						{t("members.title")}
						<Restricted
							to={Permissions.GROUP_MEMBER_EDIT}
							groupId={groupId}
						>
							<RoundButtonPlus
								icon={faPlus}
								functionCall={openCreatePopup}
							/>
						</Restricted>
					</>
				}
				text2={<DocButton link="management/portal/member" />}
			>
				<Table columns={columns} rows={rows} />
			</WhiteBlockDiv>

			<ModifyMemberPopup
				open={modifyPopupOpen}
				closeMethod={closeModifyPopup}
				modifyMethod={modifyMember}
				groupId={groupId}
				currentRole={memberModify ? memberModify.role.id : ""}
			></ModifyMemberPopup>
			<DeletePopup
				open={deletePopupOpen}
				closeMethod={closeDeletePopup}
				deleteMethod={deleteMember}
				typeOfDelete={"member"}
			></DeletePopup>
			<CreateMemberPopup
				open={createPopupOpen}
				closeMethod={closeCreatePopup}
				createMethod={createMember}
				groupId={groupId}
			></CreateMemberPopup>
		</>
	);
}
export default MembersSection;
