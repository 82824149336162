import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../../../common/layouts/Global/LoadingSpinner";
import WhiteBlockDiv from "../../../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../../../common/layouts/tables/Table";
import DeletePopup from "../../Popup/DeletePopup";

import moment from "moment";
import Permissions from "../../../../../common/config/permissions";
import Restricted from "../../../../../common/context/hooks/Restricted";
import DocButton from "../../../../../common/layouts/Global/DocButton";
import {
	RoundButtonPen,
	RoundButtonPlus,
	RoundButtonXMark,
} from "../../../../../common/layouts/Global/RoundButton";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../../../common/util/ToastUtil";
import {
	CreateGroupVideoSchedule,
	DeleteGroupVideoSchedule,
	GetGroupVideoSchedule,
	ModifyGroupVideoSchedule,
} from "../../../../requests/video/groupVideoSchedule";
import CreateGroupVideoSchedulePopup from "../../Popup/CreateGroupVideoSchedulePopup";
import ModifyMachineVideoSchedulePopup from "../../Popup/ModifyMachineVideoSchedule";
function GroupVideoSchedule({ groupId }) {
	const { t } = useTranslation();
	const { data, isLoading, refetch } = useQuery(
		[`group-${groupId}-videoScheduleGroup`],
		{
			queryFn: () => GetGroupVideoSchedule(groupId),
		}
	);
	const [createVideoSchedulePopupOpen, setCreateVideoSchedulePopupOpen] =
		useState(false);
	const [deleteVideoSchedulePopupOpen, setDeleteVideoSchedulePopupOpen] =
		useState(false);
	const [modifyVideoSchedulePopupOpen, setModifyVideoSchedulePopupOpen] =
		useState(false);
	const [videoScheduleModify, setVideoScheduleModify] = useState(null);

	const closeCreatePopup = () => {
		setCreateVideoSchedulePopupOpen(false);
	};

	const openCreatePopup = (videoSchedule) => {
		setVideoScheduleModify(videoSchedule);
		setCreateVideoSchedulePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setDeleteVideoSchedulePopupOpen(false);
	};

	const openDeletePopup = (videoSchedule) => {
		setVideoScheduleModify(videoSchedule);
		setDeleteVideoSchedulePopupOpen(true);
	};

	const closeModifyPopup = () => {
		setModifyVideoSchedulePopupOpen(false);
	};

	const openModifyPopup = (videoSchedule) => {
		setVideoScheduleModify(videoSchedule);
		setModifyVideoSchedulePopupOpen(true);
	};

	const createGroupVideoSchedule = async (videoModelId, start, end) => {
		closeCreatePopup();
		ToastLoading("createGroupSchedule");
		const data = await CreateGroupVideoSchedule(
			Number(groupId),
			videoModelId,
			start,
			end
		);
		ToastAfterLoading(
			"createGroupSchedule",
			data?.success,
			data?.data?.message
		);
		refetch();
	};

	const deleteGroupVideoSchedule = async () => {
		closeDeletePopup();
		ToastLoading("deleteGroupSchedule");
		const data = await DeleteGroupVideoSchedule(
			groupId,
			videoScheduleModify.id
		);
		ToastAfterLoading(
			"deleteGroupSchedule",
			data?.success,
			data?.data?.message
		);
		refetch();
	};

	const ModifyVideoSchedule = async (
		videoModelId,
		videoScheduleStart,
		videoScheduleEnd
	) => {
		closeModifyPopup();
		ToastLoading("updateGroupSchedule");
		const data = await ModifyGroupVideoSchedule(
			groupId,
			videoScheduleModify.id,
			videoModelId,
			videoScheduleStart,
			videoScheduleEnd
		);
		ToastAfterLoading(
			"updateGroupSchedule",
			data?.success,
			data?.data?.message
		);
		refetch();
	};

	if (isLoading) return <LoadingSpinner />;
	if (!data?.success) return null;
	const columns = [
		t("Id"),
		t("sequence.name"),
		t("date.start"),
		t("date.end"),
		t("actions"),
	];
	const rows = data?.data?.map((videoSchedule) => {
		return [
			videoSchedule.id,
			videoSchedule?.video_model?.name,

			videoSchedule.start
				? moment(videoSchedule.start).format("YYYY-MM-DD HH:mm")
				: t("discounts.no.date"),

			videoSchedule.end
				? moment(videoSchedule.end).format("YYYY-MM-DD HH:mm")
				: t("discounts.no.date"),
			<Restricted to={Permissions.GROUP_SCHEDULE_EDIT} groupId={groupId}>
				<div className="flex">
					<RoundButtonPen
						functionCall={() => openModifyPopup(videoSchedule)}
					/>

					<RoundButtonXMark
						functionCall={() => openDeletePopup(videoSchedule)}
					/>
				</div>
			</Restricted>,
		];
	});

	return (
		<WhiteBlockDiv
			className="mb-2 sm:mb-0"
			text={
				<>
					{t("groupvideoschedule.title")}{" "}
					<RoundButtonPlus functionCall={openCreatePopup} />
				</>
			}
			text2={<DocButton link="management/portal/video" />}
		>
			<Table columns={columns} rows={rows} />
			<CreateGroupVideoSchedulePopup
				open={createVideoSchedulePopupOpen}
				closeMethod={closeCreatePopup}
				createMethod={createGroupVideoSchedule}
				groupId={groupId}
			/>
			<DeletePopup
				open={deleteVideoSchedulePopupOpen}
				closeMethod={closeDeletePopup}
				deleteMethod={deleteGroupVideoSchedule}
				typeOfDelete={"schedule"}
			></DeletePopup>
			<ModifyMachineVideoSchedulePopup
				open={modifyVideoSchedulePopupOpen}
				closeMethod={closeModifyPopup}
				modifyMethod={ModifyVideoSchedule}
				groupId={groupId}
				currentVideoSchedule={
					videoScheduleModify ? videoScheduleModify : ""
				}
			/>
		</WhiteBlockDiv>
	);
}

export default GroupVideoSchedule;
