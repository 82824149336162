import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../common/context/AuthContext";
import DocButton from "../../../../../common/layouts/Global/DocButton";
import LoadingSpinner from "../../../../../common/layouts/Global/LoadingSpinner";
import {
	RoundButtonPen,
	RoundButtonPlus,
	RoundButtonXMark,
} from "../../../../../common/layouts/Global/RoundButton";
import WhiteBlockDiv from "../../../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../../../common/layouts/tables/Table";
import {
	ToastAfterLoading,
	ToastLoading,
	ToastNotSavedCustom,
} from "../../../../../common/util/ToastUtil";
import {
	CreateMemberNotif,
	DeleteMemberNotif,
	FetchMemberNotif,
	ModifyMemberNotif,
} from "../../../../requests/dashboard/Notification";
import CreateNotifMemberPopup from "../../Popup/CreateNotifMemberPopup";
import DeletePopup from "../../Popup/DeletePopup";
import ModifyNotificationPopup from "../../Popup/ModifyNotificationPopup";

function Notification({ groupId }) {
	const { t } = useTranslation();
	const { data, refetch } = useQuery([`group-${groupId}-notif`], {
		queryFn: () => FetchMemberNotif(groupId),
	});
	const { staff } = useAuth();

	const notificationTypes = {
		PRODUCT: "product",
		STORE_ORDER: "store.order",
		...(staff() ? { OFFLINE: "offline", ERRORS: "errors" } : {}),
	};
	const [deletePopupOpen, setDeletePopupOpen] = useState(false);
	const [createPopupOpen, setCreatePopupOpen] = useState(false);
	const [memberModify, setMemberModify] = useState(null);
	const [notificationModify, setNotificationModify] = useState(null);
	const [modifyNotificationPopupOpen, setModifyNotificationPopupOpen] =
		useState(false);
	const openDeletePopup = (member) => {
		setMemberModify(member);
		setDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setDeletePopupOpen(false);
	};

	const openCreatePopup = () => {
		setCreatePopupOpen(true);
	};

	const closeCreatePopup = () => {
		setCreatePopupOpen(false);
	};
	const openModifyPopup = (notification) => {
		setNotificationModify(notification);
		setModifyNotificationPopupOpen(true);
	};
	const closeModifyPopup = () => {
		setNotificationModify(null);
		setModifyNotificationPopupOpen(false);
	};
	const deleteMember = async () => {
		closeDeletePopup();
		ToastLoading("deleteNotifMember");
		await DeleteMemberNotif(groupId, memberModify.user.id);
		refetch();
		ToastAfterLoading(
			"deleteNotifMember",
			data?.success,
			data?.data?.message
		);
	};

	const createMember = async (memberEmail, active, activeSubgroup, types) => {
		if (types.length === 0) {
			ToastNotSavedCustom(t("notif.choose.type"));
			return;
		}
		closeCreatePopup();
		ToastLoading("createNotifMember");

		const data = await CreateMemberNotif(
			groupId,
			memberEmail,
			active,
			activeSubgroup,
			types
		);
		ToastAfterLoading(
			"createNotifMember",
			data?.success,
			data?.data?.message
		);

		refetch();
	};

	const modifyMember = async (userId, active, active_subgroup, types) => {
		ToastLoading("modifyNotif");
		const data = await ModifyMemberNotif(
			groupId,
			userId,
			active,
			active_subgroup,
			types
		);
		ToastAfterLoading("modifyNotif", data?.success, data?.data?.message);

		await refetch();
	};
	if (!data) return <LoadingSpinner />;

	const columns = [
		t("email"),
		t("enabled"),
		t("members.active.subgroup"),
		t("members.types"),
		t("actions"),
	];
	let rows = [];
	if (Array.isArray(data?.data)) {
		rows = data?.data?.map((member) => {
			return [
				member?.user?.email,
				member?.active ? t("true") : t("false"),
				member?.active_subgroup ? t("true") : t("false"),
				member?.types
					?.map((type) => t(notificationTypes[type]))
					?.join(", "),
				<div className="flex">
					<RoundButtonPen
						functionCall={() => openModifyPopup(member)}
					/>
					<RoundButtonXMark
						functionCall={() => openDeletePopup(member)}
					/>
				</div>,
			];
		});
	}

	return (
		<>
			<WhiteBlockDiv
				text={
					<>
						{t("members.notification.title")}
						<RoundButtonPlus functionCall={openCreatePopup} />
					</>
				}
				text2={<DocButton link="management/portal/notification" />}
			>
				<Table columns={columns} rows={rows} />
			</WhiteBlockDiv>

			<DeletePopup
				id={memberModify ? "notif" + memberModify.user.id : ""}
				open={deletePopupOpen}
				closeMethod={closeDeletePopup}
				deleteMethod={deleteMember}
				typeOfDelete={"notif"}
			></DeletePopup>

			<CreateNotifMemberPopup
				open={createPopupOpen}
				types={notificationTypes}
				closeMethod={closeCreatePopup}
				createMethod={createMember}
				groupId={groupId}
			></CreateNotifMemberPopup>
			<ModifyNotificationPopup
				id={notificationModify?.id ?? 0}
				open={modifyNotificationPopupOpen}
				types={notificationTypes}
				closeMethod={closeModifyPopup}
				modifyMethod={modifyMember}
				currentNotification={notificationModify ?? ""}
				groupId={groupId}
			/>
		</>
	);
}
export default Notification;
