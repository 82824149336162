import { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import QuitSave from "../../layouts/Machine/QuitSave";
import PriceSettings from "../../layouts/Machine/Settings/PriceSettings";
import SettingPanel from "../../layouts/Machine/Settings/SettingPanel";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Permissions from "../../../common/config/permissions";
import { useAuth } from "../../../common/context/AuthContext";
import {
	usePermission,
	useSomePermission,
} from "../../../common/context/hooks/usePermission";

function MachineSettings({
	machine,
	settings,
	setSettings,
	saveSettings,
	mapping,
}) {
	const { groupId } = useParams();
	const { isOnline } = machine;
	const { hasPermission } = useAuth();

	const { t } = useTranslation();
	const [displayed, setDisplayed] = useState("");
	const [types, setTypes] = useState([]);

	const potentialTypes = [
		{
			displayName: "prices",
			name: "Prices",
			hasPermission: useSomePermission(
				[
					Permissions.MACHINE_SETTING_CONTAINER_EDIT,
					Permissions.MACHINE_SETTING_PAYMENT_TOGGLE,
				],
				groupId
			),
			allowOfflineChanges: true,
		},
		{
			displayName: "recipes",
			name: "Recipes",
			hasPermission: useSomePermission(
				[
					Permissions.MACHINE_SETTING_RECIPE_EDIT,
					Permissions.MACHINE_SETTING_RECIPE_TOGGLE,
				],
				groupId
			),
			allowOfflineChanges: true,
		},
		{
			displayName: "options",
			name: "Options",
			hasPermission: useSomePermission(
				[
					Permissions.MACHINE_SETTING_OPTION_EDIT,
					Permissions.MACHINE_SETTING_OPTION_TOGGLE,
				],
				groupId
			),
			allowOfflineChanges: true,
		},
		{
			displayName: "valves",
			name: "Valves",
			hasPermission: usePermission(
				Permissions.MACHINE_SETTING_VALVE_EDIT,
				groupId
			),
			allowOfflineChanges: false,
		},
	];

	useEffect(() => {
		let newTypes = [];

		for (let type of potentialTypes)
			if (type.hasPermission) newTypes.push(type);

		setTypes(newTypes);
		setDisplayed(newTypes[0]?.name);

		const handleQuit = (e) => {
			e.returnValue = null;
		};

		window.addEventListener("beforeunload", handleQuit);

		return () => window.removeEventListener("beforeunload", handleQuit);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePaymentChange = (e) => {
		setSettings({
			...settings,
			payments: !settings.payments,
		});
	};
	const handleRecipeChange = (e) => {
		let id = e.target.id;
		let name = e.target.name;
		let value = e.target.value;

		if (isNaN(value)) return;

		setSettings({
			...settings,
			recipes: settings.recipes.map((r) => {
				if (r.id === Number(id)) {
					return { ...r, [name]: Number(value) };
				}
				return r;
			}),
		});
	};

	const handleRecipeFlavorChange = (e) => {
		const id = e.target.id;
		const name = e.target.name;
		const value = e.target.value;

		if (isNaN(value)) return;

		setSettings({
			...settings,
			recipes: settings.recipes.map((r) => {
				if (r.id === Number(id)) {
					return {
						...r,
						flavor: {
							...r.flavor,
							[name]: Number(value),
						},
					};
				}
				return r;
			}),
		});
	};
	const handleRecipeOptionChange = (e) => {
		const id = e.target.id;
		const name = e.target.name;
		const value = e.target.value;

		if (isNaN(value)) return;

		setSettings({
			...settings,
			recipes: settings.recipes.map((r) => {
				if (r.id === Number(id)) {
					return {
						...r,
						option: {
							...r.option,
							[name]: Number(value),
						},
					};
				}
				return r;
			}),
		});
	};

	const handleOptionsChange = (e) => {
		const id = e.target.id;
		const name = e.target.name;
		const value = e.target.value;

		if (isNaN(value)) return;

		setSettings({
			...settings,
			options: settings.options.map((s) => {
				if (s.id === Number(id)) {
					return { ...s, [name]: Number(value) };
				}
				return s;
			}),
		});
	};

	const handleValvesChange = (e) => {
		const id = e.target.id;
		const name = e.target.name;
		const value = e.target.value;

		if (isNaN(value)) return;

		setSettings({
			...settings,
			valves: settings.valves.map((v) => {
				if (v.key === id) {
					return { ...v, [name]: Number(value) };
				}
				return v;
			}),
		});
	};

	const containerMove = (index, move) => {
		let arr = settings.containers;
		const element = arr[index];

		arr.splice(index, 1);
		arr.splice(index + move, 0, element);

		setSettings({
			...settings,
			prices: arr,
		});
	};

	const addContainer = () => {
		setSettings({
			...settings,
			containers: [
				...settings.containers,
				{
					price: 0,
					sizeML: 0,
				},
			],
		});
	};

	const handleDeleteContainer = (index) => {
		let newContainers = [...settings.containers];
		newContainers.splice(index, 1);
		setSettings({
			...settings,
			containers: newContainers,
		});
	};

	const handleChangeContainer = (e, index, name) => {
		let newValue = e.target.value;

		if (isNaN(newValue)) return;

		const newContainers = [...settings.containers];
		newContainers[index][name] = newValue;
		setSettings({
			...settings,
			containers: newContainers,
		});
	};

	if (types.length === 0) return null;

	return (
		<div className="">
			<div
				className={classNames(
					"text-center flex flex-wrap justify-center gap-1 mx-auto pl-4 pr-4 md:grid max-w-md",
					{
						"grid-cols-2": types.length === 2,
						"grid-cols-3": types.length === 3,
						"grid-cols-4": types.length === 4,
					}
				)}
			>
				{types.map((type, index) => (
					<button
						key={type.name}
						className={classNames(
							"rounded-[5px] md:rounded-none p-1 px-3 min-w-fit whitespace-nowrap",
							{
								"bg-kupablue/80 hover:bg-kupablue/50 text-white  hover:shadow-md	":
									displayed === type.name,
								"bg-secondary hover:bg-secondary/60 hover:shadow-md dark:bg-secondarydark hover:dark:bg-secondarydarkhover":
									displayed !== type.name,
							},
							{
								"md:rounded-l-full": index === 0,
								"md:rounded-r-full": index === types.length - 1,
							}
						)}
						onClick={() => setDisplayed(type.name)}
					>
						{t(type.displayName)}
					</button>
				))}
			</div>

			{!isOnline && (
				<div className="mt-4 mb-4 text-sm text-center">
					{potentialTypes.find((type) => type.name === displayed)
						?.allowOfflineChanges ? (
						<p className="text-red-500">
							{t("station.offline.no.updates")}
						</p>
					) : (
						<p className="text-red-500">
							{t("station.offline.disabled.section")}
						</p>
					)}
				</div>
			)}

			{displayed === "Prices" && (
				<PriceSettings
					containers={settings.containers}
					payments={settings.payments}
					handlePaymentChange={
						hasPermission(
							Permissions.MACHINE_SETTING_PAYMENT_TOGGLE,
							groupId
						)
							? handlePaymentChange
							: undefined
					}
					addContainer={addContainer}
					handleDeleteContainer={handleDeleteContainer}
					handleChangeContainer={handleChangeContainer}
					containerMove={containerMove}
				/>
			)}

			<div className="mt-2 sm:mt-4 grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2">
				{displayed === "Recipes" &&
					settings.recipes.map((r) => {
						return (
							<SettingPanel
								key={r.id}
								values={r}
								mapping={mapping}
								handleFlavorChange={
									hasPermission(
										Permissions.MACHINE_SETTING_RECIPE_EDIT,
										groupId
									)
										? handleRecipeFlavorChange
										: undefined
								}
								handleOptionChange={
									hasPermission(
										Permissions.MACHINE_SETTING_RECIPE_EDIT,
										groupId
									)
										? handleRecipeOptionChange
										: undefined
								}
								handleChange={
									hasPermission(
										Permissions.MACHINE_SETTING_RECIPE_TOGGLE,
										groupId
									)
										? handleRecipeChange
										: undefined
								}
								step={r.key === "aucune" ? "50" : ""}
							/>
						);
					})}

				{displayed === "Options" &&
					settings.options.map((o) => (
						<SettingPanel
							key={o.id}
							values={o}
							handleChange={handleOptionsChange}
							step={o.key === "petilant" ? "50" : ""}
						/>
					))}

				{displayed === "Valves" &&
					settings.valves.map((v) => (
						<SettingPanel
							key={v.key}
							values={v}
							handleChange={
								hasPermission(
									Permissions.MACHINE_SETTING_VALVE_EDIT,
									groupId
								)
									? handleValvesChange
									: undefined
							}
							step={50}
							disabled={!isOnline}
						/>
					))}
			</div>
			<div className="fixed flex bottom-2 right-2 z-50">
				<Popup
					className="bg-white"
					trigger={
						<button className=" m-1 p-2 pl-10 pr-10 mobile:m-3 mobile:p-3 mobile:pl-12 mobile:pr-12 rounded-full bg-secondary  hover:bg-secondary/50  hover:shadow-md">
							{t("cancel")}
						</button>
					}
					modal
					contentStyle={{ width: "50%" }}
					position="top center"
				>
					{(close) => (
						<QuitSave
							name={settings.name}
							close={close}
							showNotSave={true}
							showSave={false}
							saveSettings={saveSettings}
						/>
					)}
				</Popup>

				<Popup
					trigger={
						<button className="m-1 p-2 pl-10 pr-10 mobile:m-3 mobile:p-3 mobile:pl-12 mobile:pr-12 rounded-full bg-kupablue/80 hover:bg-kupablue/50 text-white hover:shadow-md">
							{t("save")}
						</button>
					}
					modal
					contentStyle={{ width: "50%" }}
					position="left"
				>
					{(close) => (
						<QuitSave
							name={settings.name}
							close={close}
							showSave={true}
							showNotSave={false}
							saveSettings={saveSettings}
						/>
					)}
				</Popup>
			</div>
		</div>
	);
}

export default MachineSettings;
